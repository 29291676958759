.release-card {
    &__container {
      display: flex;
      gap: 16px;
  
      .icon-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
  
        .line-divider {
          background: var(--Neutral-200, #E7E6E5);
          width: 1px;
          height: 89px;
          border-radius: 8px;
        }
      }
  
      .content-container {
        border: 1px solid var(--Dawn-300, #FF9C5D);
        padding: 12px;
        border-radius: 8px;
        width: 100%;
  
        .description {
          display: flex;
          justify-content: space-between;
          width: 100%;
  
          p {
            color: var(--Neutral-800, #363430);

            line-height: 150%;
            letter-spacing: 0.32px;
            font-family: Inter;
            font-size: 1rem;
            font-weight: 600;
          }
        }
  
        .short-description {
          line-height: 150%;
          letter-spacing: 0.24px;
          font-family: Inter;
          color: var(--Neutral-500, #696763);
          font-size: 0.75rem;
          font-weight: light;
        }
  
        .date {
          border-radius: 48px;
          background: var(--Dawn-30, #FFF1EB);
          font-family: Inter;
          font-size: 0.625rem; /* 10px */
          color: var(--Dawn-500, #F57D34);
          letter-spacing: 0.2px;
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 6px 16px;
        }
      }
    }
  }
  